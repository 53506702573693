import React from "react";
import axios from 'axios';
import WriterSidebar from "./WriterSidebar";
import SideNavigation from './SideNavigation';


const MyCart = () => {
    const [data, setData] = React.useState({
        listing: [],
        isCartLoader: false,
        grand_total:0,
        cart_item_id:''
    });

    const _removeCartProduct=(itemID)=>{
        if(itemID > 0)
        {
            try {
                axios.post("/api/v1/users/remove-item.php", {
                    headers: { "Content-Type": "application/x-www-form-urlencoded;charset=utf-8" },
                    data: JSON.stringify({
                        token: parseUserInfo.token,
                        id: parseUserInfo.id,
                        item_id:itemID
                    }),
    
                }).then((res) => {
                    if (res.data.status === "OK") {
                        setData({listing:res.data.list_stories});    
                    }

                })
    
            }
            catch (error) {
                console.log("Error on all stories page=" + error);
            }   


        }
    }

    const handleConfirmation=(id)=>{
        if(window.confirm("Are you sure you want to remove this item?")){
            _removeCartProduct(id)
        }else{
            return false;
        }              
        
    }


    var parseUserInfo = JSON.parse(localStorage.getItem("user_info"));
    if (parseUserInfo.id && parseUserInfo.token) {

        try {
            axios.post("/api/v1/users/fetch-cart.php", {
                headers: { "Content-Type": "application/x-www-form-urlencoded;charset=utf-8" },
                data: JSON.stringify({
                    token: parseUserInfo.token,
                    id: parseUserInfo.id
                }),

            }).then((res) => {
                if (res.data.status === "OK") {
                    setData({ ...data, listing: res.data.list_stories, isCartLoader: true,grand_total:res.data.grand_total });
                }
            })

        }
        catch (error) {
            console.log("Error on all stories page=" + error);
        }

    }
    
    return (<div id="main">
        <div className="ct-main-content ct-index project01">

            <div id="scroll-content" style={{ overflow: 'auto' }}>
                <div className="page-content">
                    <div className="wrapper">
                        <WriterSidebar />
                        <div id="content">
                            <SideNavigation />
                            <div className="front-section">
                                <div className="container">
                                    {data.listing.length > 0 ? (
                                        <div className="row">
                                            <div className="col-sm-12 col-md-10 col-md-offset-1">
                                                <table className="table table-hover cart-table">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '40%' }}>Story Title</th>
                                                            <th className="text-center">Price</th>
                                                            <th>Operations</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data.listing.map((records, index) => (
                                                                
                                                                <tr>
                                                                    <td className="col-sm-8 col-md-6"><div className="media">
                                                                        <div className="media-body">
                                                                            <h4 className="media-heading">
                                                                                <a href=" ">{records.title}</a></h4>
                                                                        </div>
                                                                    </div></td>
                                                                    <td className="col-sm-1 col-md-1 text-center"><strong>Rs.{records.price}</strong></td>
                                                                    <td className="col-sm-1 col-md-1">
                                                                        <button onClick={()=>handleConfirmation(records.id)} type="button" className="btn btn-danger"> <i className="fa fa-trash" aria-hidden="true"></i> Remove </button></td>
                                                                </tr>
                                                            ))
                                                        }

                                                        <tr>
                                                            <td></td>
                                                            <td><h3>Total</h3></td>
                                                            <td className="text-right"><h3><strong>Rs.{data.grand_total}</strong></h3></td>
                                                        </tr>

                                                    </tbody>

                                                </table>

                                                <div className="row mt-70">
                                                    <div className="col-md-6"><button type="button" className="btn btn-success"><i className="fa fa-pencil-square-o"></i> Continue Writing </button></div>
                                                    <div className="col-md-6 text-right"><button type="button" className="btn btn-success"> Checkout <span className="fa fa-cart"></span> </button></div>
                                                </div>


                                            </div>


                                        </div>
                                    ) : (

                                        <div className="row">
                                            <div className="col-sm-12 col-md-10 col-md-offset-1">
                                                <table className="table table-hover cart-table">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '40%' }}>Story Title</th>
                                                            <th className="text-center">Price</th>
                                                            <th>f</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td className="col-sm-8 col-md-6" colSpan={3}>No records available</td>
                                                        </tr>



                                                    </tbody>


                                                </table>
                                            </div>
                                        </div>

                                    )
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div >);


}
export default MyCart;