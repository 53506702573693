import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
const Logout = () => 
{
    let navigate = useNavigate();    
    // eslint-disable-next-line
    useEffect(() => {handleLoggedOut()},[]);   
    const handleLoggedOut = () =>{         
        localStorage.removeItem("user_info");
        alert("You have successfully logged out.");
       // window.location.href='/login'
       navigate("/login");  
    }   
              
}
export default Logout;