import React, { useEffect } from 'react';
import axios from 'axios';

const Liked_Writers = () => {

    const [data, setData] = React.useState({
        listing: [],
        isScreenLoader: false,
    });
    // eslint-disable-next-line
    useEffect(() => { show_stories() }, []);

    const show_stories = () => {        
        var parseUserInfo = JSON.parse(localStorage.getItem("user_info"));
        if (parseUserInfo.id && parseUserInfo.token) {
            try {
                axios.post("/api/v1/creator/liked-writers.php", {
                    headers: { "Content-Type": "application/json" },
                    data: JSON.stringify({
                        token: parseUserInfo.token,
                        id: parseUserInfo.id
                    }),
                }).then((res) => {
                    
                    if (res.data.status === "OK") {                                            
                        setData({ ...data, listing: res.data.list_stories, isScreenLoader: true });
                    }

                });

            }
            catch (error) {
                console.log("Error on liked stories page=" + error);
            }

        }
    }
    
    
    return (<div className="front-section">
        <div className="container">
            <div className="row">
                {data.listing.length > 0 ? (
                    <div className="col-md-12 mb-30" >
                        {
                            data.listing.map((records, index) => (
                                <div className="list-box" key={records.id}>
                                    <div className="row  mb-30">
                                        <div className="col-md-7 ">
                                            <h4 className="mb-20">{records.story_title}<br />
                                            </h4>
                                        </div>

                                        <div className="col-md-5 text-right"><span className="box-catig"><strong> Work Type</strong> : Story </span> <span className="box-catig"><strong>Category</strong> : {records.story_category}  </span> </div>

                                    </div>
                                    <p>{records.list_description}</p>
                                    <hr className="list-hr" />

                                    <div className="row">
                                        <div className="col-md-6">
                                            <p><span><i className="fa fa-book" aria-hidden="true"></i> Story Type : {records.stype}</span></p>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <p><span><i className="fa fa-film" aria-hidden="true"></i> Creator Show Interest : 30</span></p>
                                        </div>
                                    </div>

                                    <hr className="list-hr" />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <button className="list-button"><i className="fa fa-copyright" aria-hidden="true"></i> Request For Getting Right Assigned</button>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <button className="list-button"><i className="fa fa-thumbs-up"></i> Liked Story</button>
                                            <button className="list-button"><i className="fa fa-check"></i> Liked Writer</button>
                                            <button className="list-button"><i className="icon-heart"></i> Wish List</button>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }

                    </div>
                ) : (
                    <div className="col-md-12 mb-30">
                        <div className="list-box" >
                            <p> No record(s) available.</p>

                        </div>
                    </div>

                )}

            </div>
        </div>
    </div>);

}
export default Liked_Writers;