import React from "react";
import axios from 'axios';
//import {BadgeContext} from './BadgeContext.js';

const MiniCart = () => {
    const [data, setData] = React.useState({
        listing: [],
        isCartLoader: false,
        badge: '',
    });


    const _closeDivHandle = () => {
        document.getElementById("cartDiv").style.display = 'none';
    }

    const _loadMiniCart = () => {
        setData({ ...data, isCartLoader: true })
        var x = document.getElementById("cartDiv");
        x.style.display = "block";
        var parseUserInfo = JSON.parse(localStorage.getItem("user_info"));
        if (parseUserInfo.id && parseUserInfo.token) {

            try {
                axios.post("/api/v1/users/fetch-cart.php", {
                    headers: { "Content-Type": "application/json" },
                    data: JSON.stringify({
                        token: parseUserInfo.token,
                        id: parseUserInfo.id,
                        badge: parseUserInfo.badgeCnt
                    }),

                }).then((res) => {
                    if (res.data.status === "OK") {
                        setData({ ...data, listing: res.data.list_stories, isCartLoader: false });

                    }
                    else
                    {
                        alert(res.data.status)
                        setData({...data,isCartLoader:false})
                    }
                })

            }
            catch (error) {
                console.log("Error on all mini cart page=" + error);
            }

        }


    }


    return(
        <><button className="nav-link cart-a cart-btn" onClick={_loadMiniCart}>
            <i className="fa fa-cart-arrow-down" aria-hidden="true"></i> Cart
            <span className="cart-circle">0</span>
        </button>
            <div className="dropdown-menu set-cart-div" id="cartDiv" style={{ display: 'block', minWidth: 400, minHeight: 100 }}>
                <div style={{ textAlign: 'right' }}>
                    <button style={{border:'0px'}} onClick={_closeDivHandle}><img src="images/cross_icon.png" alt="Close" style={{ width: 20, height: 20 }} /></button>
                </div>
                {
                    data.isCartLoader===true?(                    
                     <table className="cart-tables">
                        <tr>
                            <td align="center">
                                <img src="images/loader.gif" alt="Loader" style={{ width: 30, height: 30 }}/>
                            </td>
                        </tr>

                        <tr>
                            <td align="center">Please wait while we are fetching your cart information...</td>
                        </tr>
                     </table>

                    ):(

                        data.listing.length > 0 ? (
                            <><table className="cart-tables">
                                    {data.listing.map((records, index) => (
                                        <tr>
                                            <td style={{ width: '80%' }}> {records.title} </td>
                                            <td>{records.price} </td>
                                        </tr>
                                    ))}
                                </table><a className="checkout-btn" href=" "> Checkout</a></>
                        ) : (
                            <table className="cart-tables">
                                <tr>
                                    <td colspan="2" style={{ width: '100%' }}>Your cart is empty.</td>
                                </tr>
                            </table>
                        )


                    )
                }
        

                

            </div></>

    );                    
                 

}
export default MiniCart;