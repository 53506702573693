import React from "react";
import Background from '../pic/login-bg.jpg';
import axios from 'axios';
import { NavLink } from "react-router-dom";

const showDiv = ()=>{
    var selectedUserType = document.getElementById("user_type").value;
    if(selectedUserType==="Writer"){
        document.getElementById("writerDiv").style.display = "block";
        document.getElementById("creatorDiv").style.display = "none";
    }
    else if(selectedUserType ==="Creator"){
        document.getElementById("writerDiv").style.display = "none";
        document.getElementById("creatorDiv").style.display = "block";
    }
    else{
        document.getElementById("writerDiv").style.display = "none";
        document.getElementById("creatorDiv").style.display = "none";
    }

}

const handleRegisteration = () => {

    let name = document.getElementById("name").value;
    let mobile = document.getElementById("mobile").value;
    let email = document.getElementById("email").value;
    let user_type = document.getElementById("user_type").value;

    var associationType="";
    var assocation_name="";
    var membership_number="";

    if(user_type ==="Writer"){
         associationType = document.getElementById("writerAssociationType").value;
         assocation_name = document.getElementById("assocation_name").value;
         membership_number = document.getElementById("membership_number").value;
    }
    else if(user_type ==="Creator"){
        associationType = document.getElementById("creatorAssociationType").value;
        assocation_name = document.getElementById("creator_association_name").value;
        membership_number = document.getElementById("creator_association_number").value;
    }
        
    let password = document.getElementById("password").value;
    let repeat_password = document.getElementById("repeat_password").value;
   

    if (name && mobile && email && user_type && password && repeat_password) {
        
       if(repeat_password === password)
        {            
            try {
                axios.post("/api/v1/users/registration.php", 
                {
                    headers:{"Content-Type":"application/x-www-form-urlencoded;charset=utf-8"},                    
                    data: JSON.stringify({
                        name: name,
                        mobile: mobile,
                        password:password,
                        user_type:user_type,
                        association_type:associationType,
                        assocation_name:assocation_name,
                        membership_number:membership_number,
                        email:email
                      }),
                   
                   
                })
                .then(res => {                    
                    if(res.data.response === 200){
                        alert("You have successfully registered as "+res.data.user_label+". We've emailed you 4 digit verification code for verify your account.");
                    }
                    else{
                        alert(res.data.status);
                    }
                    
                })
            }
            catch (error) {
                alert("Error while fetching request for on contact us update=" + error)
            }
        }
        else{
            alert("Repeat password must be same as password.");
            return false;
        }

    }
    else {
        alert("All field(s) marked with * are mandatory to filled.");
        return false;
    }

}
const RegistrationTemplate = () => {
    return (
        <div id="main">
            <div className="ct-main-content ct-project02">
                <div id="scroll-content"> </div>
                <div className="page-content">
                    <div className="project-hero">
                        <div className="hero-image-wrapper reg-overlay">
                            <div className="hero-bg-image" style={{ backgroundImage: `url(${Background})` }}></div>
                        </div>
                        <div className="container">
                            <div className="regi-log">
                                <div className="row">
                                <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="hero-content ">
                                            <div className="hero-inner log-reg-bg">

                                                <div className="container">
                                                    <h1>Register</h1>
                                                    <p>Please fill in this form to login an account.</p>
                                                    <hr />
                                                    <div className="row sign-form">
                                                        <div className="col-md-6">
                                                            <input className="input__field cf-validate my-form-margin" type="text" placeholder="Full name:*" name="name" id="name" required />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <input className="input__field cf-validate my-form-margin" type="text" placeholder="Mobile Number:*" name="mobile" id="mobile" required />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <input className="input__field cf-validate my-form-margin" type="email" placeholder="Email:*" name="email" id="email" required />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <select className="input__field cf-validate my-form-margin" id="user_type" onChange={showDiv}>
                                                                <option value="">Who Are You ?</option>
                                                                <option value="Writer">I am Writer</option>
                                                                <option value="Creator">i am Creator </option>
                                                            </select>
                                                        </div>

                                                        <div className="col-md-12" id="writerDiv" style={{display:'none'}}>
                                                            <select className="input__field cf-validate my-form-margin" id="writerAssociationType">
                                                                <option value="">Are you a member of any Writers Association ?</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No </option>
                                                            </select>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <input className="input__field cf-validate my-form-margin" type="text" placeholder="Association Name" name="assocation_name" id="assocation_name" />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <input className="input__field cf-validate my-form-margin" type="text" placeholder=" Valid Membership Number" name="membership_number" id="membership_number"  />
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-md-12" id="creatorDiv" style={{display:'none'}}>
                                                            <select className="input__field cf-validate my-form-margin" id="creatorAssociationType">
                                                                <option value="">Are you a member of any Association ?</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No </option>
                                                            </select>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <input className="input__field cf-validate my-form-margin" type="text" placeholder=" Association Name" name="creator_association_name" id="creator_association_name"  />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <input className="input__field cf-validate my-form-margin" type="text" placeholder=" Valid Membership Number" name="creator_association_number" id="creator_association_number"  />
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-md-6">
                                                            <input className="input__field cf-validate my-form-margin" type="password" placeholder="Password" name="password" id="password" required />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <input className="input__field cf-validate my-form-margin" type="password" placeholder="Repeat Password" name="repeat_password" id="repeat_password" required />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <p>By creating an account you agree to our <a href="abc.html">Terms & Privacy</a>.</p>
                                                    <button type="button" onClick={handleRegisteration} className="registerbtn">Register</button>
                                                </div>


                                                <div className="container signin">
                                                    <p>Already have an account? <NavLink to="/login">Sign In</NavLink>.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default RegistrationTemplate;