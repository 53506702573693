import React, { useEffect } from 'react';
import WriterSidebar from "./WriterSidebar";
import SideNavigation from './SideNavigation';
import axios from 'axios';

const ApprovedStories = () => {

    const [data, setData] = React.useState({
        listing: [],
        isScreenLoader: false,

    });

 
    // eslint-disable-next-line
    useEffect(() => { handleStoriesList() }, []);
    const handleStoriesList = () => {
        var parseUserInfo = JSON.parse(localStorage.getItem("user_info"));
        if (parseUserInfo.id && parseUserInfo.token) {
            try {
                axios.post("/api/v1/users/approved-stories.php", {
                    headers: { "Content-Type": "application/x-www-form-urlencoded;charset=utf-8" },
                    data: JSON.stringify({
                        token: parseUserInfo.token,
                        id: parseUserInfo.id
                    }),

                }).then((res) => {                    
                    if (res.data.status === "OK") {                        
                        setData({ ...data, listing: res.data.list_stories, isScreenLoader: true });
                    }
                    
                   

                })

            }
            catch (error) {
                console.log("Error on all stories page=" + error);
            }

        }

    }
    
    if(data.isScreenLoader){
    return (<div id="main">
        <div className="ct-main-content ct-index project01">

            <div id="scroll-content" style={{ overflow: 'auto' }}>
                <div className="page-content">
                    <div className="wrapper">
                        <WriterSidebar />
                        <div id="content">                            
                                <SideNavigation/>
                            <div className="front-section">
                                <div className="container">
                                <h2>Approved Stories</h2><br/>
                                    <div className="row">
                                        {data.listing.length > 0 ? (
                                            <div className="col-md-12 mb-30" >
                                                {
                                                data.listing.map((records, index) => (
                                                <div className="list-box" key={records.id}>
                                                    <div className="row  mb-30">
                                                        <div className="col-md-8 ">
                                                            <h4 className="mb-20">{records.story_title}<br />
                                                            </h4>
                                                        </div>
                                                        <div className="col-md-4 text-right"> <span className="box-catig"><strong>Category</strong> : {records.story_category} </span> </div>
                                                    </div>                                                    
                                                    <p>{records.list_description}</p>
                                                    <hr className="list-hr" />
                                                    <span>Story Type : {records.stype}</span>
                                                </div>
                                                ))
                                            }

                                            </div>
                                        ) : (
                                            <div className="col-md-12 mb-30">
                                                <div className="list-box" >
                                                    <p> No record(s) available.</p>

                                                </div>
                                            </div>

                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
    }
    else{
        return(<div id="main">
            <div className="ct-main-content ct-index project01">
                <div id="scroll-content">
                <div className="page-content">
                    <div className="wrapper">
                        <WriterSidebar />                        
                        <div id="content">
                            
                            <div className="front-section">
                                <div className="container">
                                    <div className="row">
                                    <div className="col-md-12 mb-30" >
                                    <div className="list-box">                                                                                                      
                                        <p>Loading all stories. Please wait...</p>                                                    
                                     </div>
                                       
                                     </div>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>  
                </div>
            </div>

        </div>);
    }
}
export default ApprovedStories;