import React,{ useEffect } from 'react';
import WriterSidebar from "./WriterSidebar";
import SideNavigation from './SideNavigation';
import { useNavigate } from "react-router-dom";

const WriterDashboard = (props) => {    
    const navigate = useNavigate();  
    
    function handleUserAuth(){                                          
        if(localStorage.getItem("user_info") === null){
            navigate("/login");       
        }
    }

    var lstorage = localStorage.getItem("user_info");
    var decodedlstorage = JSON.parse(lstorage);

    // eslint-disable-next-line
    useEffect(() => {handleUserAuth()},[]);           
    return(
        <div id="main">
            <div className="ct-main-content ct-index project01">
                <div id="scroll-content">
                    <div className="page-content">
                        <div className="wrapper">
                            <WriterSidebar />
                            <div id="content">

                               <SideNavigation/>

                                <div className="front-section">
                                    <div className="container">
                                        <div className="dashbord-name">
                                            <div className="row my-flex">
                                                <div className="col-md-8 col-lg-8">
                                                    <h1>{decodedlstorage.user_name}</h1>
                                                </div>
                                                <div className="col-md-4 col-lg-4 text-right">
                                                    <img src="images/dash_name.jpg" alt="logo" /> </div>
                                            </div>
                                        </div>
                                        <div className="scor-bord">
                                            <div className="scor-bord-inner">
                                                <div className="row my-flex">
                                                    <div className="col-md-3 col-lg-3">
                                                        <h2>0</h2>
                                                        <p>TOTAL STORY</p>
                                                    </div>
                                                    <div className="col-md-3 col-lg-3">
                                                        <h2>0</h2>
                                                        <p>APPROVED STORY</p>
                                                    </div>
                                                    <div className="col-md-3 col-lg-3">
                                                        <h2>0</h2>
                                                        <p>PENDING APPROVALS</p>
                                                    </div>
                                                    <div className="col-md-3 col-lg-3">
                                                        <h2>0</h2>
                                                        <p>NOT APPROVED</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="three-box clients">
                                            <div className="row">
                                                <div className="col-md-4 col-lg-4">
                                                    <div className="client front-box">
                                                        <h3>Pending Approvals</h3>

                                                        <ul>
                                                            <li><a href="abc.html">Boogie Nights.</a></li>
                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-4">
                                                    <div className="client front-box">
                                                        <h3>Approved Story</h3>
                                                        <ul>
                                                            <li><a href="abc.html">Boogie Nights.</a></li>
                                                           
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-4">
                                                    <div className="client front-box">
                                                        <h3>NOT APPROVED</h3>
                                                        <ul>
                                                            <li><a href="abc.html">Boogie Nights.</a></li>
                                                           
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


}
export default WriterDashboard;