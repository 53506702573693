import React from 'react';
import WriterSidebar from "./WriterSidebar";
import { Editor } from "@tinymce/tinymce-react";
import axios from 'axios';
import SideNavigation from './SideNavigation';
import { useNavigate } from "react-router-dom";

const NewStory = () => {    
    let navigate = useNavigate(); 
    const [sdata, setData] = React.useState({
        ldescription:'',
        synopsis:null,
        concept:null,
        story_file:null,
        screen_play:null,
        dialogue_file:null,
        bible_file:null,
        isButtonLoader:false
        
    });
    const handleChange=(content, editor) => {
        setData({...sdata,ldescription:content})
      }
   
    function handleSaveStory(event){
         
        event.preventDefault()
       
        var validation_status=true;            
        var story_type='';
        var story_category='';
        var work_type = '';                      
        var story_title = document.getElementById("story_title").value;    
        story_type += (document.getElementById("action").checked?'Action:':'');  
        story_type += (document.getElementById("comedy").checked?'Comedy:':'');  
        story_type += (document.getElementById("drama").checked?'Drama:':'');  
        story_type += (document.getElementById("fantasy").checked?'Fantasy:':'');  
        story_type += (document.getElementById("horror").checked?'Horror:':'');  
        story_type += (document.getElementById("mystery").checked?'Mystery:':'');  
        story_type += (document.getElementById("romance").checked?'Romance:':'');  
        story_type += (document.getElementById("thriller").checked?'Thriller:':'');  
        story_type += (document.getElementById("western").checked?'Western:':'');  
        if(document.getElementById("story_category_movie").checked===true){story_category="Movie";}
        else if(document.getElementById("story_category_tv").checked===true){story_category="TV Serial";}
        else if(document.getElementById("story_category_ws").checked===true){story_category="Web Series";}
        else if(document.getElementById("story_category_documentary").checked===true){story_category="Documentary";}
        else{story_category='Not_Checked'}
        work_type += (document.getElementById("synopsis").checked?'Synopsis:':'');  
        work_type += (document.getElementById("concept").checked?'Concept:':'');  
        work_type += (document.getElementById("story").checked?'Story:':'');  
        work_type += (document.getElementById("screen_play").checked?'Screen_Play:':'');  
        work_type += (document.getElementById("dialogues").checked?'Dialogues:':'');  
        work_type += (document.getElementById("show_bible").checked?'Show_Bible:':'');  

        if(!story_title){alert("Please enter story title."); validation_status=false; return false;}
        else if(!story_type){alert("Please select at least one story type."); validation_status=false; return false;}
        else if(story_category==="Not_Checked"){alert("Please select story category."); validation_status=false; return false }
        else if(!work_type){alert("Please select at least one value of work type."); validation_status=false; return false;}   

        if(validation_status === true){
             setData({...sdata,isButtonLoader:true});
        }                    
         const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
         
          try 
          {                
                var decodedStoryInfo = JSON.parse(localStorage.getItem("user_info"));    
                const formData = new FormData();
                formData.append('file', sdata.synopsis);
                formData.append('cfile', sdata.concept); 
                formData.append('sfile', sdata.story_file);
                formData.append('splayfile', sdata.screen_play);   
                formData.append('dlgfile', sdata.dialogue_file);
                formData.append('bibfile', sdata.bible_file);                                                            
                formData.append('story_type',story_type);
                formData.append('story_category',story_category);
                formData.append('work_type',work_type);
                formData.append('story_title',story_title);
                formData.append('ldescription',sdata.ldescription);
                formData.append('user_token',decodedStoryInfo.token);
                formData.append('uid',decodedStoryInfo.id);              

                try {
                    axios.post("/api/v1/users/new-story.php", formData, config).then((res) => {                         
                    if(res.data.response === 800) {
                        alert("Your uploaded file(s) and story has been successfully saved. Your story current status is PENDING.");
                        setData({...sdata,isButtonLoader:false});
                        navigate("/my-cart");  
                        return false;
                    }   
                    else {
                        alert(res.data.status)
                        setData({...sdata,isButtonLoader:false});
                    }                   
                    
                        
                });
                }
                catch (error) {
                    alert("Error while sending request for saving add new story information=" + error)
                    setData({...sdata,isButtonLoader:false});
                }  
           
        }
        catch (error) {
            console.log("Error while getting on add new story screen=" + error);
            setData({...sdata,isButtonLoader:false});
        }

        // end saving story //


    }
    function onFileChange(e) {        
       
        const size = e.target.files[0].size;        
        var allowedExtensions = /(\.pdf|\.doc|\.docx)$/i;                
        if(size > 3145728){
            alert("Uploaded file (Synopsis) can not be more than 3MB.")
            document.getElementById("concept_file").value='';
            return false;
        }
        else if(!allowedExtensions.exec(e.target.files[0].name)){alert('Invalid file extension (.pdf,.doc files can be uploaded.)');  document.getElementById("synopsis_file").value=''; return false; }
        else{ setData({ ...sdata,synopsis:e.target.files[0]});  }

    }
    function onConceptFileChange(e){
                
        const size = e.target.files[0].size;        
        var allowedExtensions = /(\.pdf|\.doc|\.docx)$/i;                
        if(size > 3145728){
            alert("Uploaded file (Concept) can not be more than 3MB.")
            document.getElementById("concept_file").value='';
            return false;
        }
        else if(!allowedExtensions.exec(e.target.files[0].name)){alert('Invalid file extension (.pdf,.doc files can be uploaded.)'); document.getElementById("concept_file").value=''; return false; }                
        else {setData({ ...sdata,concept:e.target.files[0]});  }   
    }
    function onStoryFileChange(e){
        
        const size = e.target.files[0].size;        
        var allowedExtensions = /(\.pdf|\.doc|\.docx)$/i;                
        if(size > 3145728){
            alert("Uploaded file (Story) can not be more than 3MB.")
            document.getElementById("story_file").value='';
            return false;
        }
        else if(!allowedExtensions.exec(e.target.files[0].name)){alert('Invalid file extension (.pdf,.doc files can be uploaded.)'); document.getElementById("story_file").value=''; return false; }                                
        else {setData({ ...sdata,story_file:e.target.files[0]}); }    
    }
    function onScreenPlayFile(e){

        const size = e.target.files[0].size;        
        var allowedExtensions = /(\.pdf|\.doc|\.docx)$/i;                
        if(size > 3145728){
            alert("Uploaded file (Screen Play) can not be more than 3MB.")
            document.getElementById("screen_play_file").value='';
            return false;
        }
        else if(!allowedExtensions.exec(e.target.files[0].name)){alert('Invalid file extension (.pdf,.doc files can be uploaded.)'); document.getElementById("screen_play_file").value=''; return false; }                                
        else {setData({ ...sdata,screen_play:e.target.files[0]}); }

    }
    function onDialogueFileChange(e){
        const size = e.target.files[0].size;        
        var allowedExtensions = /(\.pdf|\.doc|\.docx)$/i;                
        if(size > 3145728){
            alert("Uploaded file (Dialogue) can not be more than 3MB.")
            document.getElementById("dialogues_file").value='';
            return false;
        }
        else if(!allowedExtensions.exec(e.target.files[0].name)){alert('Invalid file extension (.pdf,.doc files can be uploaded.)'); document.getElementById("dialogues_file").value=''; return false; }                                
        else{setData({ ...sdata,dialogue_file:e.target.files[0]}); }
    }
    function onBibleFileChange(e){
        
        const size = e.target.files[0].size;        
        var allowedExtensions = /(\.pdf|\.doc|\.docx)$/i;                
        if(size > 3145728){
            alert("Uploaded file (Bible) can not be more than 3MB.")
            document.getElementById("bible_file").value='';
            return false;
        }
        else if(!allowedExtensions.exec(e.target.files[0].name)){alert('Invalid file extension (.pdf,.doc files can be uploaded.)'); document.getElementById("bible_file").value=''; return false; }                                        
        else{setData({ ...sdata,bible_file:e.target.files[0]}); }
    }
    return (
        <div id="main">
            <div className="ct-main-content ct-index project01">
                <div id="scroll-content" style={{ overflow: 'auto' }}>
                    <div className="page-content">
                        <div className="wrapper">
                            <WriterSidebar />

                            <div id="content">
                               
                               <SideNavigation/>

                                <form onSubmit={handleSaveStory}>
                                <div className="front-section">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12 mb-30">
                                                <h1> Write Your Story </h1>
                                            </div>
                                            <div className="col-md-6">
                                                <h4>1. Write Story Title</h4>
                                                <input type="text" id="story_title" className="input__field cf-validate" placeholder="Story Title:*" />
                                            </div>

                                            <div className="col-md-12 mb-30">
                                                <h4 className="mb-30">2. Select Story Type</h4>
                                                <label className="style-check-box">Action
                                                    <input type="checkbox" id="action" value="Action" />
                                                    <span className="checkmark"></span> </label>
                                                <label className="style-check-box">Comedy
                                                    <input type="checkbox" id="comedy" value="Comedy"  />
                                                    <span className="checkmark"></span> </label>
                                                <label className="style-check-box">Drama
                                                    <input type="checkbox" id="drama" value="Drama"  />
                                                    <span className="checkmark"></span> </label>
                                                <label className="style-check-box">Fantasy
                                                    <input type="checkbox" id="fantasy" value="Fantasy"  />
                                                    <span className="checkmark"></span> </label>
                                                <label className="style-check-box">Horror
                                                    <input type="checkbox" id="horror" value="Horror"  />
                                                    <span className="checkmark"></span> </label>
                                                <label className="style-check-box">Mystery
                                                    <input type="checkbox" id="mystery" value="Mystery"  />
                                                    <span className="checkmark"></span> </label>
                                                <label className="style-check-box">Romance
                                                    <input type="checkbox" id="romance" value="Romance"  />
                                                    <span className="checkmark"></span> </label>
                                                <label className="style-check-box">Thriller
                                                    <input type="checkbox" id="thriller" value="Thriller"   />
                                                    <span className="checkmark"></span> </label>
                                                <label className="style-check-box">Western
                                                    <input type="checkbox" id="western" value="Western"  />
                                                    <span className="checkmark"></span> </label>
                                            </div>

                                            <div className="col-md-12 mb-30">
                                                <h4 className="mb-30">3. Select Story Category</h4>
                                                <label className="style-radio">Movie
                                                    <input type="radio" name="story_category" id="story_category_movie" value="Movie" />
                                                    <span className="checkmark-radio"></span> </label>
                                                <label className="style-radio">TV Serial
                                                    <input type="radio" name="story_category" id="story_category_tv" value="TV_Serial" />
                                                    <span className="checkmark-radio"></span> </label>
                                                <label className="style-radio">Web Series
                                                    <input type="radio" name="story_category" id="story_category_ws" value="Web_Series" />
                                                    <span className="checkmark-radio"></span> </label>
                                                <label className="style-radio">Documentary
                                                    <input type="radio" name="story_category" id="story_category_documentary" value="Documentary" />
                                                    <span className="checkmark-radio"></span> </label>
                                            </div>

                                            <div className="col-md-12 mb-50">
                                                <h4 className="mb-30">4. Work Type (What you will upload)</h4>

                                                <label className="style-check-box">Synopsis
                                                    <input type="checkbox" id="synopsis" value="Synopsis" />
                                                    <span className="checkmark"></span> </label>
                                                <label className="style-check-box">Concept
                                                    <input type="checkbox" id="concept" value="Concept" />
                                                    <span className="checkmark"></span> </label>
                                                <label className="style-check-box">Story
                                                    <input type="checkbox" id="story" value="Story" />
                                                    <span className="checkmark"></span> </label>
                                                <label className="style-check-box">Screen play
                                                    <input type="checkbox" id="screen_play" value="Screen_Play" />
                                                    <span className="checkmark"></span> </label>
                                                <label className="style-check-box">Dialogues
                                                    <input type="checkbox" id="dialogues" value="Dialogues" />
                                                    <span className="checkmark"></span> </label>
                                                <label className="style-check-box">Show Bible
                                                    <input type="checkbox" id="show_bible" value="Show_Bible" />
                                                    <span className="checkmark"></span> </label>
                                            </div>

                                            <div className="col-md-12 mb-50">
                                                <h4 className="mb-30">5. Write Synopsis only</h4>
                                                <div>
                                                <Editor  
                                                apiKey='soh61rm7p3p8viw41bhz9ftdi405u5iwqwdzr3xpi8z4sav2'                                             
                                                init={{                                                
                                                menubar: true,                                                
                                                plugins: [
                                                    'lists link image paste help wordcount'
                                                   ],
                                                   toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'                                                                                           
  
                                                }}
                                                onEditorChange={handleChange}
                                                />
                                                </div>
                                               
                                            </div>
                                            <div className="col-md-6 mb-30">
                                                <h4 className="mb-30">5. Upload Synopsis (If any?)</h4>
                                                <input type="file" id="synopsis_file" name="synopsis_file" className="input__field cf-validate" onChange={onFileChange} />
                                                <small>.pdf, .doc,(File size should not be more than 3MB.)</small>
                                            </div>

                                            <div className="col-md-6 mb-30">
                                                <h4 className="mb-30">Upload Concept (If any?)</h4>
                                                <input type="file" id="concept_file" name="concept_file" className="input__field cf-validate" onChange={onConceptFileChange} />
                                                <small>.pdf, .doc,(File size should not be more than 3MB.)</small>
                                            </div>

                                            <div className="col-md-6 mb-30">
                                                <h4 className="mb-30">Upload Story (If any?)</h4>
                                                <input type="file" id="story_file" name="story_file" className="input__field cf-validate" onChange={onStoryFileChange} />
                                                <small>.pdf, .doc,(File size should not be more than 3MB.)</small>
                                            </div>

                                            <div className="col-md-6 mb-30">
                                                <h4 className="mb-30">Upload Screen play (If any?)</h4>
                                                <input type="file" id="screen_play_file" name="screen_play_file" className="input__field cf-validate" onChange={onScreenPlayFile} />
                                                <small>.pdf, .doc,(File size should not be more than 3MB.)</small>
                                            </div>

                                            <div className="col-md-6 mb-30">
                                                <h4 className="mb-30">Dialogues (If any?)</h4>
                                                <input type="file" id="dialogues_file" name="dialogues_file" className="input__field cf-validate" onChange={onDialogueFileChange} />
                                                <small>.pdf, .doc,(File size should not be more than 3MB.)</small>
                                            </div>

                                            <div className="col-md-6 mb-30">
                                                <h4 className="mb-30">Show Bible (If any?)</h4>
                                                <input type="file" id="bible_file" name="bible_file" className="input__field cf-validate" onChange={onBibleFileChange} />
                                                <small>.pdf, .doc,(File size should not be more than 3MB.)</small>
                                            </div>
                                            <div className="col-md-12">
                                                {sdata.isButtonLoader?(
                                                 <button className="buttonload list-button"> <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Please wait... </button>                                                         
                                                ):(<button type="submit"  id="cf-submit" className="main-btn cursor-link" style={{ top: 0 }}>Submit Story</button>)}                                                                                                

                                            </div>


                                        </div>
                                    </div>



                                </div>
                                </form>                                                

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default NewStory;