import React from 'react';

const DashboardTemplate = () => {

    var lstorage = localStorage.getItem("user_info");
    var decodedlstorage = JSON.parse(lstorage);

    return (<div className="front-section">
        <div className="container">
            <div className="dashbord-name">
                <div className="row my-flex">
                    <div className="col-md-8 col-lg-8">
                        <h1>{decodedlstorage.user_name}</h1>
                    </div>
                    <div className="col-md-4 col-lg-4 text-right">
                        <img src="images/dash_name.jpg" alt="logo" style={{ width: 20, height: 20 }}  /> </div>
                </div>

            </div>

            <div className="scor-bord">
                <div className="scor-bord-inner">
                    <div className="row my-flex">
                        <div className="col-md-3 col-lg-3">
                            <h2>0</h2>

                            <p>LIKED STORYS</p>
                        </div>
                        <div className="col-md-3 col-lg-3">
                            <h2>0</h2>
                            <p>LIKED WRITERS</p>
                        </div>
                        <div className="col-md-3 col-lg-3">
                            <h2>0</h2>
                            <p>WISH LIST</p>
                        </div>
                        <div className="col-md-3 col-lg-3">
                            <h2>0</h2>
                            <p>NOT APPROVED</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="three-box clients">

                <div className="row">
                    <div className="col-md-4 col-lg-4">
                        <div className="client front-box">
                            <h3>Pending Approvals</h3>

                            <ul>
                                <li><a href="abc.html">Boogie Nights.</a></li>

                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                        <div className="client front-box">
                            <h3>Approved Story</h3>
                            <ul>
                                <li><a href="abc.html">Boogie Nights.</a></li>

                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                        <div className="client front-box">
                            <h3>NOT APPROVED</h3>
                            <ul>
                                <li><a href="abc.html">Boogie Nights.</a></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);

}

export default DashboardTemplate;