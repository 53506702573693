import React from "react";
import Background from '../pic/login-bg.jpg';
const AccountVerify = ()=>{
    return(
        <div id="main">
            <div className="ct-main-content ct-project02">
                <div id="scroll-content"> </div>
                <div className="page-content">
                    <div className="project-hero">
                        <div className="hero-image-wrapper reg-overlay">
                            <div className="hero-bg-image" style={{ backgroundImage: `url(${Background})` }}></div>
                        </div>
                        <div className="container">
                            <div className="regi-log">
                                <div className="row">
                                <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="hero-content ">
                                            <div className="hero-inner log-reg-bg">

                                                <div className="container">
                                                    <h1>Account Verification</h1>                                                    
                                                    <div className="row sign-form">                                                        
                                                        <div className="col-md-12">                                                            
                                                            <p>Your account has been successfully verified. You can login with your registered email and password. </p>
                                                            <p> <a href="/login">LOGIN with your account.</a>.</p>
                                                        </div>                                                                                                                
                                                    </div>
                                                    <hr />                                                    
                                                    
                                                </div>


                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default AccountVerify;

