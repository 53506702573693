import React from 'react';
import $ from 'jquery';

const SideNavigation = ()=> {

    const handleNavigationClick=()=>{
        $('#sidebar').toggleclassName('active');
    }
    return(
        <div className="top-button">           
            <button onClick={handleNavigationClick} type="button" id="sidebarCollapse" className="btn btn-info"> <i className="fas fa-align-left"></i></button>
            <button className="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <i className="fas fa-align-justify"></i> </button>
        </div>
    );

}
export default SideNavigation;