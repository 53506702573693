import React from 'react';
import axios from 'axios';

const CommonFunctions = (props) => {
   
    const [data, setData] = React.useState({
        liked_story:false,
        liked_writer:false
    });


    const _handleLikedWriters = () => {
        var storyID = props.data;
        
        if(storyID > 0){
            var parseUserInfo = JSON.parse(localStorage.getItem("user_info"));
            if (parseUserInfo.id && parseUserInfo.token) {
                try {
                    axios.post("/api/v1/creator/liked_writers.php", {
                        headers: { "Content-Type": "application/json" },
                        data: JSON.stringify({
                            token: parseUserInfo.token,
                            id: parseUserInfo.id,
                            story_Id:storyID
                        }),
                    }).then((res) => {
                        
                        if (res.data.status === "OK") {                        
                            setData({...data,liked_writer:true});                          
                        }
                        else if(res.data.status === "ALREADY"){
                            setData({...data,liked_writer:true}); 
                            alert("This writer has already been liked by you.");
                            return false;
                        }
    
                    });
    
                }
                catch (error) {
                    console.log("Error on all stories page=" + error);
                }


            }
            
        }



    }

    const _handleLikedStories = () => {
        var storyID = props.data;

        if(storyID > 0){
            var parseUserInfo = JSON.parse(localStorage.getItem("user_info"));
            if (parseUserInfo.id && parseUserInfo.token) {
                try {
                    axios.post("/api/v1/creator/liked_stories.php", {
                        headers: { "Content-Type": "application/json" },
                        data: JSON.stringify({
                            token: parseUserInfo.token,
                            id: parseUserInfo.id,
                            story_Id:storyID
                        }),
                    }).then((res) => {
                        
                        if (res.data.status === "OK") {                        
                            setData({...data,liked_story:true});                          
                        }
                        else if(res.data.status === "ALREADY"){
                            setData({...data,liked_story:true}); 
                            alert("This story has already been liked by you.");
                            return false;
                        }
    
                    });
    
                }
                catch (error) {
                    console.log("Error on all stories page=" + error);
                }


            }
            
        }

    }
    return (
        <div className="col-md-6 text-right">
            {!data.liked_story?(
            <button className="list-button" onClick={_handleLikedStories}> Liked Story
                {/* <i className="fa fa-thumbs-up"></i> Liked Story */}
            </button>
            ):(
                <button className="list-button" onClick={_handleLikedStories}> <i className="fa fa-thumbs-up"></i> Liked Story </button>
             )
            }    
            {!data.liked_writer?(
            <button className="list-button" onClick={_handleLikedWriters}> Liked Writer
                {/* <i className="fa fa-check"></i> Liked Writer */}
            </button>
            ):(
                <button className="list-button" onClick={_handleLikedWriters}> 
                    <i className="fa fa-check"></i> Liked Writer
                 </button>
            )
            }
            <button className="list-button"><i className="icon-heart"></i> Wish List</button>
        </div>
    )

}

export default CommonFunctions;