import React from "react";
import { NavLink } from "react-router-dom";

const CreatorSidebar = (props) => {        
  
        
    //const sp = new URLSearchParams('?name=Movie');
    //alert(sp.toString());

    var lstorage = localStorage.getItem("user_info");
    var decodedlstorage = JSON.parse(lstorage);
    console.log(props)
    return (
        <nav id="sidebar">
            <div className="sidebar-header">
                <img src="images/logo.png" alt="logo" /> </div>
            <ul className="list-unstyled components">
                <p className="user-block"> <img src="images/user.jpg" alt="userimage" /> 
                 {decodedlstorage.user_name}
                </p>
                <li className="active"> 
                    <button onClick={()=>{props.data('Dashboard','Dashboard')}}><i className="fa fa-home"></i> Home</button>                    
                </li>
                <li>
                    <button onClick={()=>{props.data('All_Stories','All_Stories')}}><i className="fa fa-home"></i> All Stories</button>
                </li>
                <li>                     
                <ul>
                    
                    <li><button onClick={()=>{props.data('Cat_Stories','Movie')}}> Movie </button></li>

                    <li><button  onClick={()=>{props.data('Cat_Stories','TV_Serial')}}> TV Serial </button> </li>
                    <li><button  onClick={()=>{props.data('Cat_Stories','Web_Series')}}> Web Series </button></li>                                
                    <li><button onClick={()=>{props.data('Cat_Stories','Documentary')}}> Documentary </button></li>                                                                                      
                
                </ul>                 
                </li>
                <li> 
                    <button onClick={()=>{props.data('Liked_Stories','Liked_Stories')}}> <i className="fa fa-check"></i> Liked Storys </button>                  
                </li>
                <li> 
                    <button onClick={()=>{props.data('Liked_Writers','Liked_Writers')}}> <i className="icon-clock"></i>Liked Writers </button>                                        
                </li>
                {/* 
                <li> <a href="abc.html"><i className="fa fa-money-bill-wave"></i>Wish List</a> </li>
                <li>                     
                    <NavLink to="/edit-profile"> <i className="icon-pencil"></i> Edit Profile </NavLink>                    
                </li> */}
            </ul>            
        </nav>
    );

}
export default CreatorSidebar;