import React from 'react';
import Background from '../pic/login-bg.jpg';
import axios from 'axios';
import { useNavigate,NavLink } from "react-router-dom";


const LoginTemplate=()=>{
    let navigate = useNavigate(); 
    const [data, setData] = React.useState({
        isLoggedIn: false,
        userType: "",
        buttonLoader:false
    });                
    const handleLogin=()=>{        
        var email = document.getElementById("email").value;
        var password = document.getElementById("password").value;

        if (email && password) {
            setData({...data,buttonLoader:true});
            try {
                axios.post("/api/v1/users/login.php", 
                {                    
                    headers:{
                        "Access-Control-Allow-Origin":"*"
                        
                       },                    
                    data: JSON.stringify({                        
                        password:password,                        
                        email:email
                      }),                                         
                })
                .then(res => {                        
                    if(res.data.response === 200){                        
                        let user_object = { id: res.data.id, 
                        token: res.data.rem_token,
                        user_type: res.data.user_type,
                        user_name:res.data.name};                                                                
                        localStorage.setItem("user_info", JSON.stringify(user_object)); 
                        setData({...data,isLoggedIn:true,userType:res.data.user_type,buttonLoader:false});
                                                
                        if(res.data.user_type === "Writer"){
                            navigate("/dashboard");                                                           
                        }else {
                            navigate("/creator-dashboard"); 
                        } 
                    }
                    else{                        
                        alert(res.data.status);
                        setData({...data,buttonLoader:false});
                        return false;
                    }
                    
                })
            }
            catch (error) {
                console.log("Error while fetching request for on login screen=" + error)
            }

        }
        else {
            alert("Email address and password can not be blank.");
            return false;
        }

    }                                              
    return (<div id="main">
            <div className="ct-main-content ct-project02">
                <div id="scroll-content"> </div>
                <div className="page-content">
                    <div className="project-hero">
                        <div className="hero-image-wrapper reg-overlay">
                            <div className="hero-bg-image" style={{ backgroundImage: `url(${Background})` }}></div>
                        </div>
                        <div className="container">
                            <div className="regi-log">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="hero-content ">
                                            <div className="hero-inner log-reg-bg">
                                                <div className="container">
                                                    <h1>Login</h1>
                                                    <p>Please fill in this form to login an account.</p>
                                                    <hr />
                                                    <input className="input__field cf-validate my-form-margin" type="email" placeholder="Email address*" name="email" id="email" required />
                                                    <input className="input__field cf-validate my-form-margin" type="password" placeholder="Password*" name="password" id="password" required />
                                                    <hr />

                                                    {data.buttonLoader?(                                                       
                                                    <button type="button" disabled className="registerbtn">Please wait...</button>
                                                    ):(<button type="button" onClick={handleLogin} className="registerbtn">Login</button>)
                                                    }                                                                


                                                </div>
                                                <div className="container signin">
                                                    <p>Not Registered Yet? <NavLink to="/registration">Sign Up</NavLink>.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default LoginTemplate;