import React from "react";
import MiniCart from "./MiniCart";
import { NavLink } from "react-router-dom";
const WriterHeader = () => {
    return (
        <> <header id="dashbord">
                <nav className="navbar navbar-expand-lg">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="nav navbar-nav ml-auto">
                    <li className="nav-item active">
                        <MiniCart/>
                        
                    </li>
                    <li className="nav-item"> 
                        <NavLink to="/Logout"> Logout </NavLink>                       
                    </li>
                 </ul>
                 </div>   
                </nav>                
            </header>            
        </>
    );

}

export default WriterHeader;