import React from "react";
import Header from './include/header.js';
import WriterHeader from "./include/WriterHeader.js";
import CreatorHeader from "./include/CreatorHeader.js";
//import HomeTemplate from "./include/HomeTemplate.js";
import RegistrationTemplate from "./include/RegistrationTemplate.js";
import LoginTemplate from "./include/LoginTemplate.js";
import WriterDashboard from "./include/WriterDashboard.js";
import CreatorDashboard from "./include/CreatorDashboard.js";
import NewStory from "./include/NewStory.js";
import AllStories from "./include/AllStories.js";
import WriterStories from "./include/WriterStories.js";
import PendingStories from "./include/PendingStories.js";
import ApprovedStories from "./include/ApprovedStories.js";
import AccountVerify from "./include/AccountVerify.js";
import Logout from "./include/Logout.js";
import MyCart from "./include/MyCart.js";
import EditProfile from "./include/EditProfile.js";

function MainContainer() {
  
  if (window.location.pathname === '/registration') {    
    return (<><RegistrationTemplate /></>);
  }
  else if (window.location.pathname === '/login') {
    return (<><LoginTemplate /></>);
  }
  else if (window.location.pathname === '/dashboard') {
    return (<><WriterHeader /><WriterDashboard /></>);
  }
  else if (window.location.pathname === '/creator-dashboard') {
    return (<><CreatorHeader /><CreatorDashboard /></>); //<CreatorHeader />
  }
  else if (window.location.pathname === '/new-story') {
    return (<><WriterHeader /><NewStory /></>);
  }
  else if (window.location.pathname === '/all-stories') {
    return (<><WriterHeader /><AllStories /></>);
  }
  else if (window.location.pathname === '/writer-stories') {
    return (<><CreatorHeader /><WriterStories /></>);
  }
  else if (window.location.pathname === '/pending-stories') {
    return (<><WriterHeader /><PendingStories /></>);
  }
  else if (window.location.pathname === '/approved-stories') {
    return (<><WriterHeader /><ApprovedStories /></>);
  }
  else if (window.location.pathname === '/my-cart') {
    return (<><WriterHeader /><MyCart /></>);
  }
  else if (window.location.pathname === '/edit-profile') {
    return (<><WriterHeader /><EditProfile /></>);
  }
  else if (window.location.pathname === '/verify-account') {
    return (<><Header /><AccountVerify /></>);
  }
  else if (window.location.pathname === '/Logout') {
    return (<><Logout /></>);
  }
  else{    
    alert("sdffsd")
    return(<div></div>)
  }

}
export { MainContainer };

