import React from "react";
import { NavLink } from "react-router-dom";
const WriterSidebar = () => {        
    var lstorage = localStorage.getItem("user_info");
    var decodedlstorage = JSON.parse(lstorage);
    
    return (
        <nav id="sidebar">
            <div className="sidebar-header">
                <img src="images/logo.png" alt="logo" /> </div>
            <ul className="list-unstyled components">
                <p className="user-block"> <img src="images/user.jpg" alt="userimage" /> 
                 {decodedlstorage.user_name}
                </p>

                {window.location.pathname == "/dashboard"?(
                <li className="active"> 
                    <NavLink to="/dashboard"><i className="fa fa-home"></i> Home</NavLink>
                </li>
                ):(<li> 
                    <NavLink to="/dashboard"><i className="fa fa-home"></i> Home</NavLink>
                </li>)
                }   

                {window.location.pathname === '/all-stories'?(
                <li className="active"> 
                <NavLink to="/all-stories"> <i className="fa fa-keyboard"></i>All Stories </NavLink>
                {/* <a href="abc.html"><i className="fa fa-keyboard"></i> All Storys</a>  */}
                </li>
                ):( <li> 
                    <NavLink to="/all-stories"> <i className="fa fa-keyboard"></i>All Stories </NavLink>
                    {/* <a href="abc.html"><i className="fa fa-keyboard"></i> All Storys</a>  */}
                    </li>)
                }

                {window.location.pathname === '/approved-stories'?(               
                <li className="active"> 
                    <NavLink to="/approved-stories"> <i className="fa fa-check"></i> Approved Stories </NavLink>                
                </li>
                    ):(<li> 
                        <NavLink to="/approved-stories"> <i className="fa fa-check"></i> Approved Stories </NavLink>                
                    </li>)
                 }

                 {window.location.pathname === '/pending-stories'?(
                <li className="active"> 
                    <NavLink to="/pending-stories"> <i className="icon-clock"></i>Pending Stories </NavLink>                                        
                </li>
                 ):(<li> 
                    <NavLink to="/pending-stories"> <i className="icon-clock"></i>Pending Stories </NavLink>                                        
                </li>)
                }
                {/* <li> <a href="abc.html"><i className="fa fa-money-bill-wave"></i> Payment History</a> </li>
                <li>                     
                    <NavLink to="/edit-profile"> <i className="icon-pencil"></i> Edit Profile </NavLink>                    
                </li> */}
            </ul>
            <ul className="list-unstyled CTAs">                                     
                <li> 
                    {/* <a href="#/new-story" className="download"><i className="fas fa-pen-nib"></i> Write Your Story</a> */}
                    <NavLink to="/new-story" className="download"> <i className="fas fa-pen-nib"></i> Write Your Story</NavLink> 
                </li>                                    
            </ul>
        </nav>
    );

}
export default WriterSidebar;