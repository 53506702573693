import React, { useEffect } from 'react';
import WriterSidebar from "./WriterSidebar";
import axios from 'axios';
import SideNavigation from './SideNavigation';
const EditProfile = () => {

    const [data, setData] = React.useState({
        isProfileScreenLoader: false,
        list: [],
        isButtonLoader: false

    });
    // eslint-disable-next-line
    useEffect(() => { handleFetchProfile() }, []);


    const _handleUpdate = () => {
        setData({ ...data, isButtonLoader: true });
        var name = document.getElementById("name").value;
        var mobile = document.getElementById("mobile").value;
        var assocation_name = document.getElementById("assocation_name").value;
        var membership_number = document.getElementById("membership_number").value;


        var parseUserInfo = JSON.parse(localStorage.getItem("user_info"));
        if (parseUserInfo.id && parseUserInfo.token) {
            try {
                axios.post("/api/v1/users/update-user-profile.php", {
                    headers: { "Content-Type": "application/x-www-form-urlencoded;charset=utf-8" },
                    data: JSON.stringify({
                        token: parseUserInfo.token,
                        id: parseUserInfo.id,
                        name: name,
                        mobile: mobile,
                        assocation_name: assocation_name,
                        membership_number: membership_number
                    }),

                }).then((res) => {

                    if (res.data.status === "OK") {
                        alert("Your profile information has been successfully updated.")
                        setData({ ...data, isButtonLoader: false });
                    }
                    else {
                        alert(res.data.status);
                        setData({ ...data, isButtonLoader: false });
                        return false;
                    }

                })

            }
            catch (error) {
                console.log("Error on edit profile page=" + error);
            }
        }

    }


    const handleFetchProfile = () => {
        var parseUserInfo = JSON.parse(localStorage.getItem("user_info"));
        if (parseUserInfo.id && parseUserInfo.token) {
            try {
                axios.post("/api/v1/users/user-profile.php", {
                    headers: { "Content-Type": "application/x-www-form-urlencoded;charset=utf-8" },
                    data: JSON.stringify({
                        token: parseUserInfo.token,
                        id: parseUserInfo.id
                    }),

                }).then((res) => {

                    if (res.data.status === "OK") {
                        setData({ ...data, isProfileScreenLoader: true, list: res.data.user_record })
                    }
                    else {
                        alert(res.data.status);
                        return false;
                    }

                })

            }
            catch (error) {
                console.log("Error on edit profile page=" + error);
            }
        }
    }

    return (
        <div id="main">
            <div className="ct-main-content ct-index project01">
                <div id="scroll-content">
                    <div className="page-content">
                        <div className="wrapper">

                            <WriterSidebar />
                            <div id="content">
                                <SideNavigation />

                                {data.isProfileScreenLoader===true?(
                                <div className="front-section">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-10 col-md-offset-1 edit-pro-4orm">

                                                <h2>Edit Profile</h2>
                                                <div className="row sign-form">
                                                    <div className="col-md-6">
                                                        <label>Full name</label>
                                                        <input className="input__field cf-validate" id="name" type="text" name='name' defaultValue={data.list.name} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label>Mobile Number</label>
                                                        <input className="input__field cf-validate" id='mobile' type="text" defaultValue={data.list.mobile} autocomplete="off" />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label>Email</label>
                                                        <input className="input__field cf-validate" id='email' type="email" defaultValue={data.list.email} readOnly />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label>Association Name</label>
                                                        <input className="input__field cf-validate" id='assocation_name' type="text" defaultValue={data.list.assocation_name} />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <label>Valid Membership Number</label>
                                                        <input className="input__field cf-validate" id='membership_number' type="text" defaultValue={data.list.membership_number} />
                                                    </div>
                                                </div>


                                                {data.isButtonLoader ? (
                                                    <button className="buttonload list-button"> <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Please wait... </button>
                                                ) : (
                                                    <button type="button" onClick={_handleUpdate} className="btn btn-success set-top"> <i className="fa fa-arrow-up" aria-hidden="true"></i> UPDATE </button>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ):(
                                    
                            <div className="front-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12 col-md-10 col-md-offset-1 edit-pro-4orm">
                                    <h2>Edit Profile</h2>
                                        <div className="row sign-form">
                                            <div className="col-md-12">                                            
                                                <img src="images/loader.gif" alt="Loader" style={{ width: 30, height: 30 }}/>
                                                <p>Please wait while we are fetching your profile information...</p>
                                            </div>      

                                        </div>
                                    
                                    </div>   
                                </div>
                            </div>
                            </div>    
                                )
                                
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default EditProfile;