import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import Header from './include/header.js';
import { MainContainer } from './App';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

if(window.location.pathname === "/"){    
ReactDOM.render();}
else{  
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(    
  <Router>       
    <Routes>            
      <Route path="/registration" element={<MainContainer />} />
      <Route path="/login" element={<MainContainer />} />      
      <Route path="/dashboard" element={<MainContainer />} />
      <Route path="/creator-dashboard" element={<MainContainer />} />
      <Route path="/new-story" element={<MainContainer />} />
      <Route path="/all-stories" element={<MainContainer />} />
      <Route path="/writer-stories" element={<MainContainer />} />      
      <Route path="/pending-stories" element={<MainContainer />} />
      <Route path="/approved-stories" element={<MainContainer />} />
      <Route path="/my-cart" element={<MainContainer />} />
      <Route path="/verify-account" element={<MainContainer />} />
      <Route path="/Logout" element={<MainContainer />} />  
      <Route path="/edit-profile" element={<MainContainer />} />      
       
    </Routes>    
  </Router>
  
);
}


//const root = ReactDOM.createRoot(document.getElementById('root'));

/*const root = ReactDOM;
root.render(  
  <Router>       
    <Routes>
      <Route path="/" element={<MainContainer />} />      
      <Route path="/registration" element={<MainContainer />} />
      <Route path="/login" element={<MainContainer />} />      
      <Route path="/dashboard" element={<MainContainer />} />
      <Route path="/creator-dashboard" element={<MainContainer />} />
      <Route path="/new-story" element={<MainContainer />} />
      <Route path="/all-stories" element={<MainContainer />} />
      <Route path="/writer-stories" element={<MainContainer />} />      
      <Route path="/pending-stories" element={<MainContainer />} />
      <Route path="/approved-stories" element={<MainContainer />} />
      <Route path="/my-cart" element={<MainContainer />} />
      <Route path="/verify-account" element={<MainContainer />} />
      <Route path="/Logout" element={<MainContainer />} />  
      <Route path="/edit-profile" element={<MainContainer />} />      
       
    </Routes>    
  </Router>

);
*/
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
