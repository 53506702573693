import React, { useEffect } from 'react';
import CreatorSidebar from "./CreatorSidebar";
import SideNavigation from './SideNavigation';
import { useNavigate } from "react-router-dom";
//import { useSearchParams } from 'react-router-dom';
import DashboardTemplate from './DashboardTemplate';
import WriterStories from './WriterStories';
import LikedStories from './LikedStories';
import LikedWriters from './LikedWriters';
import axios from 'axios';

const CreatorDashboard = (props) => {
    const navigate = useNavigate();

    const [cdata, setCData] = React.useState({
        listing_cats_list: [],
        template_name: 'Dashboard',
        cat_select: '',
        IsCategoriesLoading:false
    });

    function handleUserAuth() {
        if (localStorage.getItem("user_info") === null) {
            navigate("/login");
        }
    }

    const handleAllCategories = (selected_nav, selected_cat) => {

        if (selected_nav === "Dashboard") {
            setCData({ ...cdata, template_name: 'Dashboard' });
        }
        else if (selected_nav === "All_Stories") {
            setCData({ ...cdata, template_name: 'All_Stories' });
        }
        else if (selected_nav === 'Liked_Stories'){
            setCData({ ...cdata, template_name: 'Liked_Stories' });
        }
        else if (selected_nav === 'Liked_Writers'){
            setCData({ ...cdata, template_name: 'Liked_Writers' });
        }

        else if (selected_nav === "Cat_Stories") {

            setCData({ ...cdata, template_name:'Categories_Stories',cat_select: selected_cat });
            var parseUserInfo = JSON.parse(localStorage.getItem("user_info"));
            if (parseUserInfo.id && parseUserInfo.token) {
                try {                    
                    axios.post("/api/v1/creator/categories-stories.php", {
                        headers: { "Content-Type": "application/json" },
                        data: JSON.stringify({
                            token: parseUserInfo.token,
                            id: parseUserInfo.id,
                            category_name: selected_cat
                        }),
                    })
                    .then((response) => {
                        if (response.data.status === "OK") {
                            setCData({ ...cdata, listing_cats_list: response.data.list_stories,template_name:'Categories_Stories',IsCategoriesLoading:true});
                        }

                    });

                }
                catch (error) {
                    console.log("Error on all stories page=" + error);
                }

            }

        }

    }
    // eslint-disable-next-line
    useEffect(() => { handleUserAuth() }, []);
    if (cdata.template_name === 'Dashboard') {
        return (
            <div id="main">
                <div className="ct-main-content ct-index project01">
                    <div id="scroll-content">
                        <div className="page-content">
                            <div className="wrapper">
                                <CreatorSidebar data={handleAllCategories} />
                                <div id="content">
                                    <SideNavigation />
                                    <DashboardTemplate />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (cdata.template_name === 'All_Stories') {        
        return (
            <div id="main">
                <div className="ct-main-content ct-index project01">
                    <div id="scroll-content">
                        <div className="page-content">
                            <div className="wrapper">
                                <CreatorSidebar data={handleAllCategories} />
                                <div id="content">
                                    <SideNavigation />
                                    <WriterStories />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    else if (cdata.template_name === 'Liked_Stories') {        
        return (
            <div id="main">
                <div className="ct-main-content ct-index project01">
                    <div id="scroll-content">
                        <div className="page-content">
                            <div className="wrapper">
                                <CreatorSidebar data={handleAllCategories} />
                                <div id="content">
                                    <SideNavigation />
                                    <LikedStories />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    else if (cdata.template_name === 'Liked_Writers') {        
        return (
            <div id="main">
                <div className="ct-main-content ct-index project01">
                    <div id="scroll-content">
                        <div className="page-content">
                            <div className="wrapper">
                                <CreatorSidebar data={handleAllCategories} />
                                <div id="content">
                                    <SideNavigation />
                                    <LikedWriters />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    else if (cdata.template_name === 'Categories_Stories') {     
        if(cdata.IsCategoriesLoading===true){   
        return (
            <div id="main">
                <div className="ct-main-content ct-index project01">
                    <div id="scroll-content">
                        <div className="page-content">
                            <div className="wrapper">
                                <CreatorSidebar data={handleAllCategories} />
                                <div id="content">
                                    <SideNavigation />

                                    <div className="front-section">
                                        <div className="container">
                                            <h2>{ cdata.cat_select }</h2><br/>
                                            <div className="row">
                                                {cdata.listing_cats_list.length > 0 ? (
                                                    <div className="col-md-12 mb-30" >
                                                        {
                                                            cdata.listing_cats_list.map((records, index) => (
                                                                <div className="list-box" key={records.id}>
                                                                    <div className="row  mb-30">
                                                                        <div className="col-md-7 ">
                                                                            <h4 className="mb-20">{records.story_title}<br />
                                                                            </h4>
                                                                        </div>

                                                                        <div className="col-md-5 text-right"><span className="box-catig"><strong> Work Type</strong> : Story </span> <span className="box-catig"><strong>Category</strong> : {records.story_category}  </span> </div>

                                                                    </div>
                                                                    <p>{records.list_description}</p>
                                                                    <hr className="list-hr" />

                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <p><span><i className="fa fa-book" aria-hidden="true"></i> Story Type : {records.stype}</span></p>
                                                                        </div>
                                                                        <div className="col-md-6 text-right">
                                                                            <p><span><i className="fa fa-film" aria-hidden="true"></i> Creator Show Interest : 30</span></p>
                                                                        </div>
                                                                    </div>

                                                                    <hr className="list-hr" />
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <button className="list-button"><i className="fa fa-copyright" aria-hidden="true"></i> Request For Getting Right Assigned</button>
                                                                        </div>
                                                                        <div className="col-md-6 text-right">
                                                                            <button className="list-button"><i className="fa fa-thumbs-up"></i> Liked Story</button>
                                                                            <button className="list-button"><i className="fa fa-check"></i> Liked Writer</button>
                                                                            <button className="list-button"><i className="icon-heart"></i> Wish List</button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            ))
                                                        }

                                                    </div>
                                                ) : (
                                                    <div className="col-md-12 mb-30">
                                                        
                                                        <div className="list-box" >
                                                            <p> No record(s) available.</p>

                                                        </div>
                                                    </div>

                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        }
        else{
            return (
                <div id="main">
                    <div className="ct-main-content ct-index project01">
                        <div id="scroll-content">
                            <div className="page-content">
                                <div className="wrapper">
                                    <CreatorSidebar data={handleAllCategories} />
                                    <div id="content">
                                        <SideNavigation />
    
                                        <div className="front-section">
                                            <div className="container">
                                                <div className="row">
                                                <div className="col-md-12 mb-30">
                                                    <p><img src='images/loader.gif' alt='Loader'/></p>
                                                    <p>Please wait... while we are loading the records.</p>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }


}
export default CreatorDashboard;