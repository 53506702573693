import React from "react";
import { NavLink } from "react-router-dom";
function Header() {
    return (<>
    <header>
          <div className="logo-container"> <a href="index-2.html" id="logo" className="cursor-link" data-type="page-transition"> 
           </a> </div>
          <div className="menu-burger">
            <div className="parallax-wrap burger-wrap">
              <div className="parallax-icon">
                <div className="burger-box">
                  <div className="burger-inner"></div>
                </div>
              </div>
            </div>
            <div className="burger-text">
              <div className="text-menu">MENU</div>
              <div className="text-open">OPEN</div>
              <div className="text-close">CLOSE</div>
            </div>
          </div>
        </header>           
    
    <div id="menu-overlay">
        <div className="container">
          <div className="content-wrapper">
            <div className="primary-nav">
              <ul>
                <li><a href="index.html" className="menu-link cursor-link" data-type="page-transition">Index</a></li>
                <li><a href="index.html" className="menu-link cursor-link" data-type="page-transition">About</a></li>
                <li>
                  <NavLink to="registration" className="menu-link cursor-link" data-type="page-transition">Contact</NavLink>
                </li>
              </ul>
            </div>
            <div className="company-info"> <a href="index.html" className="info-email link-underline cursor-link">hello@example.com</a><br />
              <a href="index.html" className="info-tel link-underline cursor-link">(222) 123-4567</a>
              <div className="info-address">
                <p>121 King St,  VIC 3000, Mumbai, india.</p>
              </div>
              <ul className="sm-links">
                <li><a href="abc.html" className="cursor-link"><i className="fab fa-instagram"></i></a></li>
                <li><a href="xyz.html" className="cursor-link"><i className="fab fa-facebook"></i></a></li>
                <li><a href="abc.html" className="cursor-link"><i className="fab fa-twitter"></i></a></li>
                <li><a href="xyz.html" className="cursor-link"><i className="fab fa-linkedin"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div></>)
}
export default Header;